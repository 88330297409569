import classes from "./Experience.module.css";
import ExperienceSection from "../components/UI/ExperienceSection";
import experience from "../assets/experience";

export default function ExperiencePage() {
  return (
    <div className={`${classes["long-content"]}`}>
      <h1 className="title">EXPERIENCE</h1>
      {experience.map((e) => (
        <ExperienceSection
          key={e.id}
          title={e.title}
          purpose={e.purpose}
          technologies={e.technologiesUsed}
          contribution={e.myContribution}
          link={e.link}
        />
      ))}
    </div>
  );
}
