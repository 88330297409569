const experience = [
  {
    id: 1,
    link: "https://github.com/SummerSchoolAcademy2022/SS-FrontEnd",
    myContribution:
      "I worked on the project on the front-end and I stood out by solving tasks with different levels of difficulty, from easy to difficult.",
    purpose:
      "The aim of this project was to create a functional website for a Cake Shop. The back-end team created a database and the front-end team built an application that used the database to render all the products. ",
    technologiesUsed: "React, Redux, JavaScript, CSS and HTML",
    title: "Summer School Academy",
  },
  {
    id: 2,
    link: "https://github.com/flavia121duta/react_app",
    myContribution:
      "This was an individual project. During the semester I learned how to work with React. Step by step I did the final project of which I am proud. I did not limit myself to just fulfilling the requirements for the project.  I thrive on the feeling of accomplishment that comes with pushing myself to achieve something difficult.",
    purpose:
      'This was a project during college for the subject "Front-end advanced React & Angular". I chose a fictional shop of jewelry as a theme that has lots of features. Some of them are connecting to a database, login session, forms, change profile option, cart, page of details for each product, filters, and so on.',
    technologiesUsed: "React, Redux, JavaScript, CSS and HTML",
    title: "Jewelry Store",
  },
  {
    id: 3,
    link: "https://github.com/flavia121duta/blockchain-project",
    myContribution:
      "I was responsible with creating the site that hosted the transactions and all the logic behind. I also had to take care about legal and regulatory issues. We both worked for the way transaction worked.",
    purpose:
      'This was the final project for the subject "Blockchain" during 3rd year of college. It was made by my and a fellow. It was a huge challenge for us that needed a deep understanding of the frameworks. The biggest challenge was to ensure the security of the platform and the transactions happening on it. The platform was able to handle a large volume of transactions.',
    technologiesUsed:
      "The NFT Marketplace was made using Next.js and Typescript as frameworks. The smart contracts were made with Solidity and Ganach was used as a private Blockchain. ",
    title: "NFT Marketplace",
  },
  {
    id: 4,
    link: "https://github.com/flavia121duta/Bookstore",
    myContribution:
      "There were many elements to consider, such as choosing a domain name and designing the site's layout. Additionally, it was challenging to determine what content to include and how to present it in a compelling way. The technical aspects of website creation, such as coding and troubleshooting, were also a bit overwhelming for a beginner like me.",
    purpose:
      "This project was the first web application I've ever made. It aimed to create a fictional Bookstore according to similar sites for regular stores.",
    technologiesUsed: "EJS,  JavaScript, HTML and CSS",
    title: "Bookstore",
  },
];

export default experience;
